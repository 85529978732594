function logEvent(eventName) {
    if (typeof window.bananas !== 'undefined') {
        window.bananas('event', eventName);
    }
}

class CookieBarComponent {
    constructor(element) {
        this.element = element;

        this.cookieConsentSummary = {
            element: null,
        };
        this.cookieConsentDetails = {
            element: null,
        };

        this.privacyDelcaration = {
            element: null,
        };

        // Open consent button
        this.openConsentDetailsButton = {
            element: null,
        };

        // Accept buttons
        this.defaultCookieAcceptButton = {
            element: null,
        };

        this.allCookieAcceptButton = {
            element: null,
        };

        this.functionalCookieAcceptButton = {
            element: null,
        };
    }

    init() {
        if (this.getCookie('cookieconsent_status') !== 'accept_all' && this.getCookie('cookieconsent_status') !== 'accept_functional') {
            this.element.style.display = 'block';
            // logEvent('cookiebar.show');
        }

        this.bootstrap();

        this.defaultCookieAcceptButton.element.addEventListener('click', () => {
            this.acceptAllCookies();
            // logEvent('cookiebar.accept-default');
        });
        this.allCookieAcceptButton.element.addEventListener('click', () => {
            this.acceptAllCookies();
            // logEvent('cookiebar.accept');
        });
        this.functionalCookieAcceptButton.element.addEventListener('click', () => {
            this.acceptFunctionalCookies();
            logEvent('cookiebar.reject');
        });
        this.openConsentDetailsButton.element.addEventListener('click', (event) => {
            event.preventDefault();
            this.openCookieConsent();
            logEvent('cookiebar.more-info');
        });
    }

    bootstrap() {
        this.cookieConsentSummary.element = this.element.querySelector('[id*="cookie_consent_summary"]');
        this.cookieConsentDetails.element = this.element.querySelector('[id*="cookie_consent_details"]');
        this.privacyDelcaration.element = this.element.querySelector('[id*="privacy_declaration"]');
        this.openConsentDetailsButton.element = this.element.querySelector('[id*="open-consent-details"]');

        this.defaultCookieAcceptButton.element = this.element.querySelector('[id*="default-accept-button"]');
        this.allCookieAcceptButton.element = this.element.querySelector('[id*="all-cookie-accept"]');
        this.functionalCookieAcceptButton.element = this.element.querySelector('[id*="functional-cookie-accept"]');
    }

    acceptAllCookies() {
        this.setCookie('cookieconsent_status', 'accept_all', 730);
        this.element.style.display = 'none';
    }

    acceptFunctionalCookies() {
        this.setCookie('cookieconsent_status', 'accept_functional', 730);
        this.element.style.display = 'none';

        if (typeof window.bananas !== 'undefined') {
            window.bananas('rejectConsent');
        }
    }

    /* eslint-disable */
    setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = `expires=${d.toUTCString()}`;
        document.cookie = `${cname}=${cvalue};${expires};path=/`;
    }

    getCookie(cname) {
        const name = `${cname}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i += 1) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    /* eslint-enable */

    openCookieConsent() {
        this.element.style.display = 'block';
        this.cookieConsentSummary.element.style.display = 'none';
        this.cookieConsentDetails.element.style.display = 'flex';

        fetch('https://vergelijkgroep.nl/privacy-consent/privacy.html')
            .then((response) => response.text())
            .then((html) => {
                this.privacyDelcaration.element.innerHTML = html;
            });
    }
}

function registerCookiebar() {
    const cookiebarElement = document.getElementById('cookie_consent');
    if (cookiebarElement) {
        const cookiebar = new CookieBarComponent(cookiebarElement);

        cookiebar.init();
    } else {
        setTimeout(registerCookiebar, 50);
    }
}

registerCookiebar();
